const defaultIconPath = "./img/icons/safari-pinned-tab.svg";

export function changeFavicon(data) {
  var link =
    document.querySelector("link[rel*='icon']") ||
    document.createElement("link");
  link.type = "image/x-icon";
  link.rel = "shortcut icon";
  const favicon = data.favicon
    ? data.favicon
    : data.logo
    ? data.logo
    : defaultIconPath;
  link.href = favicon;

  document.getElementsByTagName("head")[0].appendChild(link);

  changeMetaTag("og:title", data.brandCode);
  changeMetaTag("og:description", data.brandCode);
  changeMetaTag("og:image", favicon);
}

export function changeMetaTag(property, content) {
  let metaTag = document.querySelector(`meta[property='${property}']`);

  metaTag = document.createElement("meta");
  metaTag.setAttribute("property", property);
  if (content) metaTag.setAttribute("content", content);
  document.head.appendChild(metaTag);
}
