require("dotenv").config();

const fetchIconsFromAPI = async () => {
  try {
    const FIXBRANDID = process.env.VUE_APP_FIX_BRAND_ID;
    if (!FIXBRANDID) throw new Error("Missing FIXBRANDID");

    const response = await fetch(
      `${process.env.VUE_APP_BASE_API_URL}/api/Brand/GetAnonymous/${FIXBRANDID}`
    );
    const data = await response.json();

    console.log(data);
    return data;
  } catch (error) {
    console.error("Failed to fetch icons from API:", error);

    return null;
  }
};

module.exports = { fetchIconsFromAPI };
